<template>
  <div class="header p_sticky">
    <div class="wrap">
      <div class="title flex-row flex-center">
        <router-link to="/" class="home">
          <img :src="logo" class="logo" />
          <span class="title-text"> 中医临床药学服务<br />与调剂教育平台 </span>
        </router-link>
        <ul class="nav-menu flex-row justify-evenly flex1">
          <li
            v-for="(item, index) in links"
            :key="index"
            :class="{ active: item.active }"
          >
            <router-link :to="item.url">{{ item.name }}</router-link>
          </li>
        </ul>
        <Avatar :changeDeatail="changeDeatail" style="margin-right: 37px" />
      </div>
    </div>
  </div>
  <!-- <img class="swiper-img" style="display: block; width: 1200px; margin: 0 auto"
      :src="require('../pages/app/images/introduce.jpg')" /> -->
  <div style="background: #f9f9f9; min-height: 100vh">
    <div style="display: flex; padding-top: 1.5rem" class="wrap">
      <div
        style="
          min-width: 900px;

          display: flex;
          flex-direction: column;
        "
      >
        <!-- tabs -->
        <div
          style="
            height: 69px;
            width: 100%;
            background: #fff;
            border-radius: 5px;
          "
        >
          <div class="tabs">
            <div class="tab">
              <div
                :class="`${areaActivekey == '0' ? 'tabActive' : ''}`"
                @click="goDetail('0')"
              >
                <p>我的专区</p>
              </div>
              <span style="padding-top: 17px">|</span>
              <div
                :class="`${areaActivekey == '1' ? 'tabActive' : ''}`"
                @click="goDetail('1')"
              >
                <p>专区视频</p>
              </div>
              <div
                :class="`${areaActivekey == '2' ? 'tabActive' : ''}`"
                @click="goDetail('2')"
              >
                <p>专区文章</p>
              </div>
              <!-- border-bottom:${
                  collectkey === item.collectIndex ? '3px' : '0px'
                } solid #FF9552;
                color:${
                  collectkey === item.collectIndex ? '#FF9552' : '#000'
                };padding-bottom: 5px;`" -->
            </div>
            <router-link
              to="/"
              style="color: #ff9552; cursor: pointer; padding-top: 17px"
              >切换回首页 >
            </router-link>
          </div>
        </div>
        <!-- 我的专区 -->
        <div
          v-if="areaActivekey == '0'"
          class="contentBox"
          style="padding: 10px"
        >
          <!-- swiper -->
          <div class="swiper-container homebarswiper">
            <div class="swiper-wrapper">
              <div
                class="bar-swiper swiper-slide"
                v-for="(item, index) in bars"
                :key="index"
              >
                <div class="swiper-link">
                  <img class="swiper-img" :src="item.imgUrl" />
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>

          <div style="margin-top: 20px">
            <div class="contentArea">
              <div class="flex-between-center">
                <div class="flex-between-center">
                  <img
                    style="width: 20px; height: 20px"
                    :src="require('../assets/video.png')"
                  />
                  <p style="margin-left: 10px">专区视频</p>
                </div>
                <p
                  @click="goDetail('1')"
                  style="color: #ff9552; cursor: pointer"
                >
                  更多 >>
                </p>
              </div>
              <div style="display: flex; width: 100%; flex-wrap: nowrap">
                <div
                  class="articleStyle"
                  v-for="(item, index) in courses"
                  :key="index"
                  @click="toCourse(item)"
                >
                  <div
                    style="width: 100%; border-radius: 6px; position: relative"
                  >
                    <img
                      style="width: 12rem; height: 7rem; object-fit: cover"
                      :src="item.imgUrl"
                    />
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <div class="ellipsis2 articleName">
                      {{ item.chapterName || "" }}
                    </div>
                    <div class="articleOther">
                      <div>{{ item.createTime || "" }}</div>
                      <div style="display: flex">
                        <img
                          style="width: 1rem; margin-right: 0.1rem"
                          :src="require('../assets/eyeOpen.png')"
                          alt=""
                        />
                        <p>{{ item.numberOfView || 0 }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="contentArea" style="margin-top: 20px">
              <div class="flex-between-center">
                <div class="flex-between-center">
                  <img
                    style="width: 20px; height: 20px"
                    :src="require('../assets/article.png')"
                  />
                  <p style="margin-left: 10px">专区文章</p>
                </div>
                <p
                  @click="goDetail('2')"
                  style="color: #ff9552; cursor: pointer"
                >
                  更多 >>
                </p>
              </div>
              <div style="display: flex; width: 100%; flex-wrap: nowrap">
                <div
                  class="articleStyle"
                  v-for="(item, index) in articles"
                  :key="index"
                  @click="toArticle(item)"
                >
                  <div
                    style="width: 100%; border-radius: 6px; position: relative"
                  >
                    <img
                      style="width: 12rem; height: 7rem; object-fit: cover"
                      :src="item.headImgUrl"
                    />
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <div class="ellipsis2 articleName">
                      {{ item.articleName || "" }}
                    </div>
                    <div class="articleOther">
                      <div>{{ item.appPublishTime || "" }}</div>
                      <div style="display: flex">
                        <img
                          style="width: 1rem; margin-right: 0.1rem"
                          :src="require('../assets/eyeOpen.png')"
                          alt=""
                        />
                        <p>{{ item.numberOfView || 0 }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="areaActivekey == '1'">
          <div
            style="
              background: #fff;
              margin-top: -6px;
              color: #999;
              border-radius: 5px;
            "
          >
            <div
              style="height: 50px; cursor: pointer"
              class="d-flex align-center"
            >
              <div
                style="margin-left: 35px"
                @click="courseDetail(item.id)"
                :class="
                  coursePartSortType === item.id ? 'color_f3' : 'color_a5'
                "
                v-for="(item, index) in coursePart"
                :key="index"
              >
                <div>{{ item.name || "" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="areaActivekey == '2'">
          <div
            style="
              background: #fff;
              margin-top: -4px;
              color: #999;
              border-radius: 5px;
            "
          >
            <div
              style="height: 50px; cursor: pointer"
              class="d-flex align-center"
            >
              <div
                style="margin-left: 35px"
                @click="artDetail(item.id)"
                :class="
                  articlePartSortType === item.id ? 'color_f3' : 'color_a5'
                "
                v-for="(item, index) in articlePart"
                :key="index"
              >
                <div>{{ item.name || "" }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="areaActivekey != '0'" class="contentBox">
          <div
            style="
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              padding: 1rem 2rem;
            "
          >
            <div
              class="articleStyle"
              v-for="(item, index) in articleDetails"
              :key="index"
              @click="toDetail(item)"
            >
              <div style="width: 100%; border-radius: 6px; position: relative">
                <img
                  style="width: 12rem; height: 7rem; object-fit: cover"
                  :src="item.imgUrl || item.headImgUrl || ''"
                />
              </div>
              <div style="display: flex; flex-direction: column">
                <div class="ellipsis2 articleName">
                  {{ item.chapterName || item.articleName || "" }}
                </div>
                <div class="articleOther">
                  <div>{{ item.webPublishTime || item.createTime || "" }}</div>
                  <div style="display: flex">
                    <img
                      style="width: 1rem; margin-right: 0.1rem"
                      :src="require('../assets/eyeOpen.png')"
                      alt=""
                    />
                    <p>{{ item.numberOfView || 0 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!articleDetails.length">
            <p
              style="
                font-size: 17px;
                text-align: center;
                color: #999;
                line-height: 67vh;
              "
            >
              暂无数据
            </p>
          </div>
        </div>
      </div>

      <div
        class="d-flex"
        style="
          margin-left: 20px;
          min-width: 280px;
          height: 500px;
          border-radius: 5px;
          flex-direction: column;
        "
      >
        <div
          class="flex-between-center"
          style="
            padding: 40px;
            background: #fff;
            flex-direction: column;
            height: 200px;
            border-radius: 5px;
          "
        >
          <img
            style="width: 8rem; height: 8rem; object-fit: cover"
            :src="areaInfo.zoneImgUrl"
            alt=""
          />
          <p style="text-align: center">{{ areaInfo.zoneName }}</p>
        </div>
        <!-- <div style="margin-top:20px;height:280px;background: #fff;border-radius:5px;">
                        <div v-for="(item, index) in areaArr" :key="index">
                            <div
                                @click="changeTab(item.myIndex)"
                                :style="`
                                border-left:${
                                    activekey === item.myIndex ? '3px' : '0px'
                                } solid orange;
                                background:${activekey === item.myIndex ? '#f9ecd9' : '#fff'};
                                padding-left:${activekey === item.myIndex ? '45px' : '48px'} `"
                                class="right-tabs"
                            >
                                {{ item.name || ''}}
                            </div>
                        </div>
                    </div> -->
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import { get, getLogindata, post } from "../utils/common";
import Swiper from "swiper";
import moment from "moment";

import router from "@/router";
import { login } from "@/components/Login";
import "swiper/css/swiper.min.css";
const logo = require("../assets/logo.jpg");
@Options({
  components: {},
})
export default class Home extends Vue {
  logo = logo;
  courses = [];
  courseDetails = [];
  coursePart = [];
  articlePart = [];
  articleDetails = [];
  articles = [];
  bars = [];
  swiper;
  areaInfo = {};
  areaActivekey = "0";
  activekey = "1";
  sortType = "0";
  coursePartSortType = "0";
  articlePartSortType = "0";
  partId = "";
  areaArr = [
    {
      myIndex: "1",
      name: "我的专区",
    },
    // {
    //     myIndex: "2",
    //     name: "我的收藏",
    // },
    // {
    //     myIndex: "3",
    //     name: "资料与账号",
    // },
  ];

  mounted() {
    this.load();
    // this.lessonArr.unshift({id:null,name:'全部'})
  }

  beforeUnmount() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }

  load() {
    //右侧专区信息
    post(`hospitalZoneWeb/getIndex`, {}, (res) => {
      console.log("右侧专区信息", res.data);
      this.areaInfo = res.data;
      post(
        `hospitalZoneWeb/RotationChartHospitalZoneId`,
        { hospitalZoneId: res.data.id },
        (data) => {
          this.bars = (data && data.data) || [];
          if (this.bars.length) {
            setTimeout(() => {
              this.swiper = new Swiper(".swiper-container.homebarswiper", {
                autoplay: {},
                pagination: {
                  el: ".swiper-pagination",
                },
              });
            }, 100);
          }
        }
      );
      // web_platform/listPlatformCourse  课程列表
      post(`hospitalZoneWeb/getIndexVideo`, {}, (data) => {
        this.courses =
          (data && data.data).map((item) => {
            item.createTime = item.createTime
              ? moment(item.createTime).format("YYYY-MM-DD HH:mm")
              : "";
            return item;
          }) || [];
      });
      // web_platform/getArticleDetail  文章列表
      post(`hospitalZoneWeb/getIndexArticle`, {}, (data, res) => {
        this.articles = data.data.map((i) => {
          i.appPublishTime = i.appPublishTime
            ? moment(i.appPublishTime).format("YYYY-MM-DD HH:mm")
            : "";
          return i;
        });
      });

      if (res.data.id) {
        get(`web_platform/getCommonArticlePart?zone=${res.data.id}`, (res) => {
          res.data.unshift({ id: "0", name: "全部" });
          this.articlePart = res.data;
        });

        get(`web_platform/getCommonCoursePart?zone=${res.data.id}`, (res) => {
          res.data.unshift({ id: "0", name: "全部" });
          this.coursePart = res.data;
        });
      }
    });
  }
  courseDetail(partId) {
    this.partId = partId;
    this.coursePartSortType = partId;
    //如果点到全部的话 那么就不传值
    post(
      `hospitalZoneWeb/listZoneVideos`,
      { partId: partId == "0" ? null : partId },
      (res) => {
        this.articleDetails = [];
        this.articleDetails = res.map((i) => {
          i.createTime = i.createTime
            ? moment(i.createTime).format("YYYY-MM-DD HH:mm")
            : "";
          return i;
        });
      }
    );
  }
  artDetail(partId) {
    this.partId = partId;
    this.articlePartSortType = partId;
    post(
      `hospitalZoneWeb/listZoneArticle`,
      { partId: partId == "0" ? null : partId },
      (res) => {
        this.articleDetails = [];
        this.articleDetails = res.map((i) => {
          i.webPublishTime = i.webPublishTime
            ? moment(i.webPublishTime).format("YYYY-MM-DD HH:mm")
            : "";
          return i;
        });
      }
    );
    // this.sortType = partId;

    // if (!!this.loading && !this.hasmore) {
    //   return;
    // }

    // if (clearArticle) {
    //   this.articles = [];
    // }

    // this.loading += 1;
    // post(
    //   `web_platform/listPlatformArticle`,
    //   {
    //     page: { from: this.articles.length, size: this.size },
    //     params: { partId, sortType },
    //   },
    //   (data, res) => {
    //     // this.loading -= 1;
    //     const list = (data && data.data) || [];
    //     list.forEach((item) => {
    //       item.day = item.webPublishTime
    //         ? moment(item.webPublishTime).format("YYYY.MM.DD")
    //         : null;
    //       this.articles.push(item);
    //     });
    //     // this.hasmore = list.length === this.size;
    //   },
    //   (err) => {
    //     // this.loading -= 1;
    //   }
    // );
  }
  toDetail(item) {
    document.documentElement.scrollTop = 0;
    if (item.articleName) {
      router.push("/zarticle/" + this.areaInfo.id + "/" + item.id);
    }
    if (item.chapterName) {
      router.push("/zcourse/" + this.areaInfo.id + "/" + item.courseId);
    }
  }
  toCourse(item) {
    document.documentElement.scrollTop = 0;
    router.push("/zcourse/" + this.areaInfo.id + "/" + item.courseId);
  }

  toArticle(item) {
    document.documentElement.scrollTop = 0;
    router.push("/zarticle/" + this.areaInfo.id + "/" + item.id);
  }
  goDetail(index) {
    this.areaActivekey = index;
    //恢复到显示全部的条目
    this.coursePartSortType = "0";
    this.articlePartSortType = "0";
    document.documentElement.scrollTop = 0;
    // 更多课程栏目及详情
    if (index == "1") {
      post(`hospitalZoneWeb/listZoneVideos`, {}, (res) => {
        this.articleDetails = [];
        this.articleDetails = res.map((i) => {
          i.createTime = i.createTime
            ? moment(i.createTime).format("YYYY-MM-DD HH:mm")
            : "";
          return i;
        });
      });
    }
    //更多文章栏目及详情
    if (index == "2") {
      post(`hospitalZoneWeb/listZoneArticle`, {}, (res) => {
        this.articleDetails = [];
        this.articleDetails = res.map((i) => {
          i.webPublishTime = i.webPublishTime
            ? moment(i.webPublishTime).format("YYYY-MM-DD HH:mm")
            : "";
          return i;
        });
      });
    }
  }

  changeTab(index) {
    //我的收藏页面有特殊样式 需要把背景色变透明 重新写样式

    // index==1的时候点不动 后续有需求
    if (index == "1") {
      document.documentElement.scrollTop = 0;

      return;
    }
    // if(index === '2'){
    //   state.collecOpcity = true;
    // }else{
    //   state.collecOpcity =false;
    // }
    // // console.log(index, "dssa");
    // state.activekey = index;r
    // console.log(state.activekey);
    document.documentElement.scrollTop = 0;
    console.log(router);
    router.push("/my?myIndex=" + index);
  }
  changeDeatail() {
    this.goDetail("0");
  }
}
</script>

<style scoped lang="less">
.right-tabs {
  margin-top: 1rem;
  padding-left: 48px;
  line-height: 3rem;
  height: 3rem;
  cursor: pointer;
}
.color_a5 {
  color: #a5a5a5;
}

.color_f3 {
  color: #ff9552;
}
.contentBox {
  margin: 20px 0px;
  background: #fff;
  padding: 10px 0 28px 0;
  border-radius: 5px;
}
.tabs {
  height: 58px;
  border-bottom: 1px solid #d7d2d2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.tab {
  color: #999;
  display: flex;
  height: 100%;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    width: 145px;
    height: 100%;
    margin-top: 17px;
    justify-content: center;
    cursor: pointer;
  }
  // div:hover{
  //     border-bottom: 2px solid orange;
  //     width: 100%;
  //     box-sizing: border-box;
  //     padding-top: 19px;
  // }
  p:hover {
    height: 44px;
    line-height: 40px;
    // height: 100%;
    border-bottom: 2px solid #ff9552;

    padding-top: 2px;
    box-sizing: border-box;
  }
}
.tabActive p {
  color: #000;
  line-height: 40px;
  border-bottom: 2px solid #ff9552;
  box-sizing: border-box;
  padding-top: 2px;
}
.homebarswiper {
  height: 400px;
}

.bar-swiper {
  height: 400px;
}

.swiper-link {
  width: 100%;
  height: 100%;
  display: block;

  .swiper-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentArea {
  display: flex;
  width: 100%;
  font-size: 17px;
  flex-direction: column;
  margin-bottom: 20px;
}
.articleStyle {
  width: 10rem;
  height: 13rem;
  overflow: hidden;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  border-radius: 6px;
  box-shadow: 3px 3px 6px #888888;
  display: flex;
  flex-direction: column;
  // max-width: 10rem;
  cursor: pointer;
  .articleName {
    margin: 13px 8px 0rem 8px;
    padding-bottom: 1rem;
    height: 26px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
  }
  .articleOther {
    display: flex;
    font-size: 12px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    opacity: 0.5;
    padding: 0.6rem 0.3rem;
  }
  // &:hover{
  //   .cancelCollect{
  //     display: block;
  //   }
  // }
}
// .cancelCollect{
//   position: absolute;
//   z-index: 9999;
//   padding: 4px 6px;
//   right: 10px;
//   top: 5px;
//   background: rgba(59, 52, 52,.5);
//   color: #fff;
//   border-radius:5px ;
//   font-size: 12px;
//   display: none;
// }
</style>